<template>
  <div>
    <ElementCard
      v-for="(section, index) in Object.keys(notificationsSettings)"
      :key="index"
      shadow="none"
      class="notifications__card"
    >
      <h3>
        <i class="el-icon-message-solid" />
        {{ NOTIFICATIONS_SECTIONS[section] }}
      </h3>
      <div class="notifications__group">
        <table class="notifications__table">
          <tr>
            <th />
            <th />
            <th />
          </tr>
          <tr
            v-for="(notification, idx) in notificationsSettings[section]"
            :key="idx"
          >
            <td class="notifications__table-title">
              <p>{{ Object.values(notification)[0].title }}</p>
            </td>
            <td class="notifications__table-toggle">
              <ElementSwitch
                v-if="
                  NOTIFICATIONS_SETTINGS_PLATFORM.EMAIL in
                    Object.values(notification)[0]
                "
                v-model="Object.values(notification)[0].email"
                inactive-text="Email"
                :disabled="isDisabledForType(Object.keys(notification)[0])"
                @change="
                  updateNotificationSettings(
                    section,
                    Object.keys(notification)[0]
                  )
                "
              />
            </td>
            <td class="notifications__table-toggle">
              <ElementSwitch
                v-if="
                  NOTIFICATIONS_SETTINGS_PLATFORM.IN_APP in
                    Object.values(notification)[0]
                "
                v-model="Object.values(notification)[0].in_app"
                inactive-text="In-app"
                @change="
                  updateNotificationSettings(
                    section,
                    Object.keys(notification)[0]
                  )
                "
              />
            </td>
          </tr>
        </table>
      </div>
    </ElementCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  NOTIFICATIONS_EMAIL_DISABLED_TYPES,
  NOTIFICATIONS_SECTIONS,
  NOTIFICATIONS_DEFAULT_STATE,
  NOTIFICATIONS_SETTINGS_PLATFORM
} from '@/constants/notifications'
import { USER_ROLE } from '@/constants/roleType'

export default {
  name: 'NotificationsSettings',

  data () {
    return {
      NOTIFICATIONS_SECTIONS,
      NOTIFICATIONS_SETTINGS_PLATFORM,
      notificationsSettings: NOTIFICATIONS_DEFAULT_STATE,
      isLoading: false
    }
  },

  computed: {
    ...mapGetters({
      settings: 'getNotificationsSettings',
      user: 'getCognitoUserData'
    }),

    /**
     * @returns {boolean}
     */
    isVendorUser () {
      return this.user.role === USER_ROLE.VENDOR.name
    }
  },

  async created () {
    this.isLoading = true
    await this.fetchNotificationsSettings()
    this.initInput()
    this.isLoading = false
  },

  methods: {
    ...mapActions([
      'fetchNotificationsSettings',
      'updateNotificationsSettings'
    ]),

    /**
     *
     */
    initInput () {
      for (const section in NOTIFICATIONS_DEFAULT_STATE) {
        // Skip if section is ADMIN and user is Vendor
        // Skip if section is VENDOR and user is an internal one
        if (
          (this.isVendorUser && section === 'ADMIN') ||
          (!this.isVendorUser && section === 'VENDOR')
        ) {
          delete NOTIFICATIONS_DEFAULT_STATE[section]
          continue
        }

        Object.values(NOTIFICATIONS_DEFAULT_STATE[section]).forEach(
          notification => {
            const notificationType = Object.keys(notification)[0]

            Object.assign(
              this.notificationsSettings[section].find(
                item => notificationType in item
              )[notificationType],
              this.settings[notificationType]
            )
          }
        )
      }
    },

    /**
     * Checks if a notification type is disabled.
     *
     * @param   {string}  type The type of notification to check.
     *
     * @returns {boolean}      True if the notification type is disabled, false otherwise.
     */
    isDisabledForType (type) {
      return NOTIFICATIONS_EMAIL_DISABLED_TYPES.includes(type)
    },

    /**
     * @param {string} section
     * @param {string} notification
     */
    async updateNotificationSettings (section, notification) {
      const updatedNotification = this.notificationsSettings[section].find(
        item => notification in item
      )[notification]
      const payload = {
        [notification]: {
          email: updatedNotification.email,
          in_app: updatedNotification.in_app
        }
      }

      await this.updateNotificationsSettings(payload)
    }
  }
}
</script>

<style lang="scss" scoped>
$notifications-group-margin: 16px 0 0 20px;

table {
  width: 100%;
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

td,
th {
  padding: spacing(1);
  text-align: left;
}

.notifications__card {
  margin-bottom: spacing(1);
}

.notifications__card h3 {
  font-weight: $font-weight-medium;
  font-size: spacing(2.5);
}

.notifications__group {
  margin: $notifications-group-margin;
}

.notifications__table {
  width: percentage(70);
}

.notifications__table th {
  padding: 0;
}

.notifications__table td {
  padding: spacing(0.5);
}

.notifications__table-title {
  width: percentage(40);
}

.notifications__table-toggle {
  width: percentage(15);
}

.notifications__table p {
  font-size: spacing(2);
}
</style>

<style lang="scss">
.el-switch__label.is-active {
  color: $grey-light;
}
</style>
