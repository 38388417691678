<template>
  <div class="settings">
    <div class="settings__container">
      <h2>Notifications and Activity</h2>
      <NotificationsSettings />
    </div>
  </div>
</template>

<script>
import NotificationsSettings from '@/components/layout/TheHeader/components/notifications/components/NotificationsSettings.vue'

export default {
  name: 'Settings',

  components: { NotificationsSettings },

  data: () => ({}),

  methods: {}
}
</script>

<style lang="scss" scoped>
.settings {
  height: 100%;
}

.settings__container {
  height: 100%;
  padding: spacing(6) spacing(4);
  overflow-y: scroll;
}

.settings__container::-webkit-scrollbar {
  display: none;
}

.settings__container h2 {
  margin-bottom: spacing(2.5);
}

.settings__card {
  margin-bottom: spacing(4);
  padding: spacing(2);

  h1 {
    margin-bottom: spacing(1);
  }

  p {
    margin-bottom: spacing(2);
  }

  .settings__list-main {
    @include text-title-small;
  }

  .settings__list-sub {
    @include text-body;

    margin: spacing(1) 0;
    padding-left: spacing(2);
    list-style: none;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
