var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((Object.keys(_vm.notificationsSettings)),function(section,index){return _c('ElementCard',{key:index,staticClass:"notifications__card",attrs:{"shadow":"none"}},[_c('h3',[_c('i',{staticClass:"el-icon-message-solid"}),_vm._v(" "+_vm._s(_vm.NOTIFICATIONS_SECTIONS[section])+" ")]),_c('div',{staticClass:"notifications__group"},[_c('table',{staticClass:"notifications__table"},[_c('tr',[_c('th'),_c('th'),_c('th')]),_vm._l((_vm.notificationsSettings[section]),function(notification,idx){return _c('tr',{key:idx},[_c('td',{staticClass:"notifications__table-title"},[_c('p',[_vm._v(_vm._s(Object.values(notification)[0].title))])]),_c('td',{staticClass:"notifications__table-toggle"},[(
                _vm.NOTIFICATIONS_SETTINGS_PLATFORM.EMAIL in
                  Object.values(notification)[0]
              )?_c('ElementSwitch',{attrs:{"inactive-text":"Email","disabled":_vm.isDisabledForType(Object.keys(notification)[0])},on:{"change":function($event){_vm.updateNotificationSettings(
                  section,
                  Object.keys(notification)[0]
                )}},model:{value:(Object.values(notification)[0].email),callback:function ($$v) {_vm.$set(Object.values(notification)[0], "email", $$v)},expression:"Object.values(notification)[0].email"}}):_vm._e()],1),_c('td',{staticClass:"notifications__table-toggle"},[(
                _vm.NOTIFICATIONS_SETTINGS_PLATFORM.IN_APP in
                  Object.values(notification)[0]
              )?_c('ElementSwitch',{attrs:{"inactive-text":"In-app"},on:{"change":function($event){_vm.updateNotificationSettings(
                  section,
                  Object.keys(notification)[0]
                )}},model:{value:(Object.values(notification)[0].in_app),callback:function ($$v) {_vm.$set(Object.values(notification)[0], "in_app", $$v)},expression:"Object.values(notification)[0].in_app"}}):_vm._e()],1)])})],2)])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }